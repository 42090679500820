<template>
	<div class="container">
		<p v-if="loaded && result">支付成功，回到<span @click="toPC">个人中心</span></p>
		<p v-if="loaded && !result">支付失败，回到<span @click="toPC">个人中心</span></p>
	</div>
</template>
<script>
export default {
	name: 'payResult',
	data() {
		return {
			result: false,
			loaded: false
		}
	},
	mounted() {
		let vm = this,
			query = vm.$route.query;
			console.log(query)
		if (query && query.result) {
			vm.result = parseInt(query.result) === true;
		}
		vm.loaded = true;
	},
	methods: {
		toPC() {
			this.$router.push({
				path: '/user'
			})
		}
	}
}
</script>
<style lang="less" scoped>
.container {
	position: relative;
	height: 100vh;
	p {
		position: absolute;
		width: 100%;
		text-align: center;
		top: 50%;
		transform: translateY(-50%);
		font-size: 20px;
		color: rgb(90, 90, 90);

		span {
			margin: 0 5px;
			color: #1989fa;
		}
	}
}
</style>